import React from "react";
import { motion } from "framer-motion";
import { fadeIn } from "../variants";

const Contact = () => {
  return (
    <section className="py-16 lg:section" id="contact">
      <div className="container mx-auto">
        <div className="flex flex-col lg:flex-row">
          <motion.div
            variants={fadeIn("right", 0.3)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: false, amount: 0.3 }}
            className="flex-1 flex justify-start items-center"
          >
            <div>
              <h4 className="text-xl uppercase text-accent font-medium mb-2 tracking-wide">
                İletişime Geç
              </h4>
              <h2 className="text-[45px] lg:text-[90px] leading-none mb-12">
                Beraber <br />
                çalışalım
              </h2>
            </div>
          </motion.div>
          <motion.div
            variants={fadeIn("left", 0.3)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: false, amount: 0.3 }}
            className="flex-1 border rounded-2xl flex flex-col gap-y-6 pb-24 p-6 items-start"
          >
            <div className="bg-transparent border-b py-3 w-full">
              <label className="block text-white text-sm font-medium mb-2">
                Telefon:
              </label>
              <p className="text-white">+90 546 597 9192</p>
            </div>
            <div className="bg-transparent border-b py-3 w-full">
              <label className="block text-white text-sm font-medium mb-2">
                Adres:
              </label>
              <p className="text-white">Çiğli/İzmir Türkiye</p>
            </div>
            <div className="bg-transparent border-b py-3 w-full">
              <label className="block text-white text-sm font-medium mb-2">
                E-posta:
              </label>
              <p className="text-white">halilibrahim.dursun@outlook.com</p>
            </div>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
