import React from "react";

import CountUp from "react-countup";

import { useInView } from "react-intersection-observer";
import { motion } from "framer-motion";
import { fadeIn } from "../variants";

const About = () => {
  const [ref, inView] = useInView({
    threshold: 0.5,
  });
  return (
    <section className="section" id="about" ref={ref}>
      <div className="container mx-auto">
        <div className="flex flex-col lg:flex-row gap-y-0 h-screen lg:items-center lg:gap-x-20">
          <motion.div
            variants={fadeIn("right", 0.3)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: false, amount: 0.3 }}
            className="flex-1 bg-about bg-contain bg-no-repeat h-[640px] mix-blend-lighten bg-top"
          ></motion.div>
          <motion.div
            variants={fadeIn("left", 0.3)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: false, amount: 0.3 }}
            className="flex-1"
          >
            <h2 className="h2 mt-3 ">Hakkımda</h2>
            <h3 className="h3">
              Ben Halil ibrahim Dursun yeni mezun yazılım mühendisiyim web mobil
              alanlarında çalışmalar yapıyorum
            </h3>
            <p className="mb-6">
              Merhaba, ben Halil İbrahim. Yazılıma olan derin ilgim ve merakım,
              kariyerime yön veren temel unsurlardan biridir. Sürekli olarak
              yeni teknolojileri öğrenmeye ve uygulamaya çalışarak, projelerde
              etkili çözümler sunma konusundaki becerilerimi geliştiriyorum.
              Yeniliklere açık bir yaklaşımla, sektördeki gelişmeleri takip
              ediyor ve her projede daha iyi bir yazılımcı olma hedefiyle
              ilerliyorum.
            </p>
            <div className="flex gap-x-6 lg:gap-x-10 mb-12 items-center">
              <div>
                <div className="text-[35px] font-tertiary text-gradient mt-4">
                  {inView ? <CountUp start={0} end={2} duration={4} /> : null}+
                </div>
                <div className="font-primary text-sm tracking-[2px]">
                  Yıllık <br /> deneyim
                </div>
              </div>

              <div>
                <div className="text-[35px] font-tertiary text-gradient mt-4">
                  {inView ? <CountUp start={0} end={112} duration={4} /> : null}
                  +
                </div>
                <div className="font-primary text-sm tracking-[2px]">
                  Başarılı <br /> Proje
                </div>
              </div>

              <div>
                <div className="text-[35px] font-tertiary text-gradient mt-4">
                  {inView ? <CountUp start={0} end={250} duration={4} /> : null}
                  +
                </div>
                <div className="font-primary text-sm tracking-[2px]">
                  Memnun <br /> Müşteri
                </div>
              </div>
            </div>
            <div className="flex gap-x-8 items-center">
              <button className="btn btn-lg">Daha fazla bilgi</button>
              <a href="#work" className="text-gradient btn-link">
                Portfolyo{" "}
              </a>
            </div>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default About;
