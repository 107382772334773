import React from "react";
import Logo from "../assets/halilibrahimdursun.png";

const Header = () => {
  return (
    <div className=" py-8">
      <div className="container mx-auto ">
        <div className="flex justify-between items-center">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.halilibrahimdursun.com.tr"
          >
            <img src={Logo} className="w-[250px] h-[200px]" alt="logo" />
          </a>
          <a
            href="tel:05465979192"
            className="btn btn-sm flex items-center justify-center"
          >
            İletişim
          </a>
        </div>
      </div>
    </div>
  );
};

export default Header;
