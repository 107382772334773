import React from "react";
import Image from "../assets/halilibrahimdursunn.jpg";
import { FaGithub, FaDribbble, FaLinkedinIn } from "react-icons/fa";
import { TypeAnimation } from "react-type-animation";
import { motion } from "framer-motion";
import { fadeIn } from "../variants";

const Banner = () => {
  return (
    <section
      className="min-h-[85vh] lg:min-h-[78vh] flex items-center"
      id="home"
    >
      <div className="container mx-auto ">
        <div className="flex flex-col  gap-y-8 lg:flex-row lg-items-cente lg:gap-x-12 ">
          <div className="flex-1 text-center font-secondary lg:text-left">
            <motion.h1
              variants={fadeIn("up", 0.3)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: false, amount: 0.7 }}
              className="text-[55px] font-bold leading-[0.8] lg:text-[110px]"
            >
              Halil ibrahim <span> Dursun</span>
            </motion.h1>
            <motion.div
              variants={fadeIn("up", 0.4)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: false, amount: 0.7 }}
              className="mb-6 text-[36px] lg:text-[60px] font-secondary font-semibold uppercase leading-[1]"
            >
              <span className="mr-4 text-white"> Ben</span>
              <TypeAnimation
                sequence={[
                  "Software Engineer",
                  2000,
                  "Frontend Developer",
                  2000,
                  "Backend Developer",
                  2000,
                  "Mobil Developer",
                  2000,
                ]}
                speed={50}
                className="text-accent"
                wrapper="span"
                repeat={Infinity}
              />
            </motion.div>
            <motion.p
              variants={fadeIn("up", 0.5)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: false, amount: 0.7 }}
              className="mb-8 max-w-lg mx-auto lg:mx-0 "
            >
              Merhaba, ben Halil İbrahim. Yazılıma olan derin ilgim ve merakım,
              kariyerime yön veren temel unsurlardan biridir. Sürekli olarak
              yeni teknolojileri öğrenmeye ve uygulamaya çalışarak, projelerde
              etkili çözümler sunma konusundaki becerilerimi geliştiriyorum.
              Yeniliklere açık bir yaklaşımla, sektördeki gelişmeleri takip
              ediyor ve her projede daha iyi bir yazılımcı olma hedefiyle
              ilerliyorum.
            </motion.p>
            <motion.div
              variants={fadeIn("up", 0.6)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: false, amount: 0.7 }}
              className="flex max-w-max gap-x-6 items-center mb-10 mt-5 mx-auto lg:mx-0"
            >
              <a
                href="#contact"
                className="btn btn-sm flex items-center justify-center"
              >
                İletişim
              </a>
              <a className="text-gradient btn-link" href="#services">
                Calısmalarım
              </a>
            </motion.div>
            <motion.div
              variants={fadeIn("up", 0.7)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: false, amount: 0.7 }}
              className="flex text-[20px] gap-x-6 max-w-max mx-auto lg:mx-0"
            >
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://github.com/hymlife"
              >
                <FaGithub />
              </a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.linkedin.com/in/halil-ibrahim-dursun/"
              >
                <FaLinkedinIn />
              </a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.halilibrahimdursun.com.tr"
              >
                <FaDribbble />
              </a>
            </motion.div>
          </div>
          <motion.div
            variants={fadeIn("down", 0.5)}
            initial="hidden"
            whileInView={"show"}
            className="hidden lg:flex flex-1 max-w-[320px] lg:max-w-[482px] "
          >
            <img src={Image} alt="" />
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default Banner;
