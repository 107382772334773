import React from "react";
import { BsArrowUpRight } from "react-icons/bs";
import { motion } from "framer-motion";
import { fadeIn } from "../variants";

const services = [
  {
    name: "Mobile Developer",
    description: "React Native mobil uygulama geliştirme",
    link: "Daha Fazlası",
    linkhref: "#",
  },

  {
    name: "Frontend Web Developer",
    description:
      "React, .NETCORE html css js teknolojileri ile web uygulama geliştirme",

    link: "Daha Fazlası",
    linkhref: "#",
  },

  {
    name: "Backend Developer",
    description: "Node.js php laravel ile backend geliştirme",

    link: "Daha Fazlası",
    linkhref: "#",
  },
];

const Services = () => {
  return (
    <section className="section" id="services">
      <div className="container mx-auto">
        <div className="flex flex-col lg:flex-row">
          <motion.div
            variants={fadeIn("right", 0.3)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: false, amount: 0.3 }}
            className="flex-1 lg:bg-services lg:bg-bottom bg-no-repeat mix-blend-lighten mb-10 lg:mb-0"
          >
            <h2 className="h2 text-accent  mb-6">What I Do</h2>
            <h3 className="h3 max-w-[455px] mb-16">
              Ben Halil ibrahim Dursun yeni mezun yazılım mühendisiyim web mobil
              alanlarında çalışmalar yapıyorum
            </h3>
            <a
              href="https://github.com/hymlife"
              target="_blank"
              rel="noopener noreferrer"
              className="btn btn-sm h-[48px] items-center justify-center py-2"
            >
              Çalışmalarım
            </a>
          </motion.div>
          <motion.div
            variants={fadeIn("left", 0.3)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: false, amount: 0.3 }}
            className="flex-1"
          >
            <div>
              {services.map((service, index) => {
                const { name, description, link, linkhref } = service;
                return (
                  <div
                    className="border-b border-white/20 h-[146px] mb-[38px] flex"
                    key={index}
                  >
                    <div className="max-w-[470px]">
                      <h4 className="text-[20px] tracking-wider font-primary font-semibold mb-5 ">
                        {name}
                      </h4>
                      <p className="font-secondary leading-tight mb-4">
                        {description}
                      </p>
                    </div>
                    <div className="flex flex-col flex-1 items-end">
                      <a className="btn w-9 h-9 mb-[42px] flex items-center justify-center">
                        <BsArrowUpRight />
                      </a>
                      <a className="text-gradient text-sm">{link}</a>
                    </div>
                  </div>
                );
              })}
            </div>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default Services;
